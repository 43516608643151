import {iosVhFix} from './utils/ios-vh-fix';
import {initModals} from './modules/modals/init-modals';
import {initAccordions} from './modules/init-accordion';
import {initTabs} from './modules/init-tabs';
// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    initAccordions();
    initTabs();

    $('input[type="tel"]').inputmask('+7 (999) 999-99-99');

    $('[data-burger]').on('click', function() {
      let $this = $(this),
          $list = $('[data-burger-list]');
          // $overlay = $('.header-overlay');

      // $('html').toggleClass('of-hidden');
      $this.toggleClass('is-active');
      $list.toggleClass('is-active');
      // $overlay.fadeIn(300);
    });

    $('[data-tab]').on('click', function() {
      let $this = $(this),
          data = $this.attr('data-tab'),
          $parent = $this.parents('[data-tab-parent]'),
          $target = $parent.find(`[data-tab-content="${data}"]`);

      $('[data-tab]').removeClass('is-active');
      $('[data-tab-content]').removeClass('is-active');

      $this.addClass('is-active');
      $target.addClass('is-active');
    });

    $('[data-city-trigger]').on('click', function() {
      let $this = $(this),
          $parent = $this.parents('[data-city-parent]'),
          $content = $parent.find('[data-city-content]');

      $content.slideToggle(300);
    });
    $('[data-city-option]').on('click', function() {
      let $this = $(this),
          data = $this.attr('data-city-option'),
          $parent = $this.parents('[data-city-parent]'),
          $target = $parent.find('[data-city-name]'),
          $content = $parent.find('[data-city-content]');
      
      $target.text(data);
      $content.slideUp(300);
    });

    $('.header__search-trigger').on('click', function() {
      let $this = $(this),
          $parent = $this.parents('.header__search'),
          $nav = $('.header__nav'),
          $list = $('[data-search-list]');


      $parent.toggleClass('is-active');
      $nav.toggle();
      if($list.hasClass('is-active')) {
        $list.removeClass('is-active')
      }
    });

    $('[data-search-field]').on('input', function() {
      let $list = $('[data-search-list]');

      if(!$list.hasClass('is-active')) {
        $list.addClass('is-active');
      }
      if($(this).val() == '') {
        $list.removeClass('is-active');
      }
    });
    $('[data-search-field]').on('focusout', function() {
      let $list = $('[data-search-list]');

      $list.removeClass('is-active');
    });

    if(window.innerWidth <= 1350) {
      $('[data-dropdown-toggle]').on('click', function() {
        let $this = $(this),
            $parent = $this.parents('[data-dropdown]'),
            $content = $parent.find('[data-dropdown-content]');
          
        $parent.toggleClass('is-active');
        $content.slideToggle(300);
      });
    }


    let exampleSliders = document.querySelectorAll('.examples__slider');
    let exampleSwiper;
    if(exampleSliders) {

      let exampleDescription = document.querySelectorAll('.examples__desc-swiper');
      let exampleDescriptionSwiper;
      if(exampleDescription) {
        exampleDescription.forEach(function(slider) {
          exampleDescriptionSwiper = new Swiper(slider, {
            slidesPerView: 1,
            loop: true,
            effect: "fade",
            allowTouchMove: false
          });
        });
      }

      exampleSliders.forEach(function(slider) {
        exampleSwiper = new Swiper(slider, {
          slidesPerView: 1,
          loop: true,
          autoplay: {
            delay: 9000,
            disableOnInteraction: false,        
          },

          navigation: {
            prevEl: slider.querySelector('.--prev'),
            nextEl: slider.querySelector('.--next')
          },

          thumbs: {
            swiper: exampleDescriptionSwiper,
          },
        });
      });

    }



    let profileSliders = document.querySelectorAll('.profile__slider');
    if(profileSliders) {
      profileSliders.forEach(function(slider) {
        let swiper = new Swiper(slider, {
          loop: true,

          navigation: {
            nextEl: slider.parentNode.querySelector('.--next')
          },
          breakpoints: {
            0: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            801: {
              slidesPerView: 3.7,
              spaceBetween: 12,
            },
            1461: {
              slidesPerView: 5,
              spaceBetween: 12,
            }
          }
        });
      });
    }

    function initQuiz() {
      let $quiz = $('[data-quiz-form]'),
          $steps = $('[data-quiz-step]'),
          stepsAmount = $steps.length,
          $allStepsNum = $('[data-quiz-all-steps]'),
          $stepsParent = $('[data-quiz-step-parent]'),
          $currentStepNum = $('[data-quiz-current-num]'),
          $progressBar = $('[data-quiz-progress]'),
          $comletionPercent = $('[data-quiz-completion-percent]'),
          $nextBtn = $('[data-quiz-nav="next"]'),
          $prevBtn = $('[data-quiz-nav="prev"]');

      $allStepsNum.text(stepsAmount);
      $steps.eq(0).addClass('is-active');
      $prevBtn.hide();
      updateProgress(1);

      $nextBtn.on('click', function() {
        let $current = $('[data-quiz-step].is-active'),
        currentNum = Number($current.attr('data-quiz-step')),
        nextNum = currentNum+1;

        if(currentNum<stepsAmount) {
          $current.removeClass('is-active');
          $(`[data-quiz-step="${nextNum}"]`).addClass('is-active');
          updateProgress(nextNum);
          $('[data-quiz-current-step]').text(nextNum).attr('data-quiz-current-step', nextNum);
        } else {
          $current.removeClass('is-active');
          $('.quiz__navigation').hide();
          $('[data-quiz-final]').addClass('is-active');
        }
        if(currentNum==1) {
          $prevBtn.show();
        }
      });

      $prevBtn.on('click', function() {
        let $current = $('[data-quiz-step].is-active'),
        currentNum = Number($current.attr('data-quiz-step')),
        prevNum = currentNum-1;

        if(currentNum>1) {
          $current.removeClass('is-active');
          $(`[data-quiz-step="${prevNum}"]`).addClass('is-active');
          updateProgress(prevNum);
          $('[data-quiz-current-step]').text(prevNum).attr('data-quiz-current-step', prevNum);
        }
        if(prevNum==1) {
          $(this).hide();
        }
      });

      $quiz.on('submit', function(e) {
        e.preventDefault();

        let $this = $(this),
            formData = new FormData(this);

        for(let [name, value] of formData) {
          console.log(`${name} = ${value}`); // key1=value1, потом key2=value2
        }

        // аякс запрос, при успешной отправке отобразить шаг success
        $('[data-quiz-final]').removeClass('is-active');
        $('[data-quiz-success]').addClass('is-active');

      });

      function updateProgress(currentNumber) {
        let percentComplition = (currentNumber / stepsAmount) * 100;
        $progressBar.css('width', percentComplition+'%');
        $comletionPercent.text(Math.round(percentComplition)+'%');
      }

    }

    initQuiz();

    $('.discust__file').on('change', function(e) {
      let $this = $(this),
          files = $(this)[0].files,
          $itemsParent = $('.discust__items'),
          $items = $itemsParent.find('.discust__item');

      console.log($(this)[0].files);

      if(files.length <= 4) {
        $itemsParent.addClass('is-active');
        $items.removeClass('is-active');
  
        for (var i = 0; i < files.length; i++) {
          $items.eq(i).addClass('is-active');
        }
      }

    });

  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✅

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
